<template>
  <div id="create-work-order">
    <van-nav-bar
      :title="$t('workOrder.create')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-form ref="form" @submit="submit" label-width="5rem">
      <van-cell-group>
        <van-field :label="$t('workOrder.type')">
          <template #input>
            <van-radio-group v-model="workOrderForm.type" direction="horizontal">
              <van-radio :name="1">{{ $t('workOrder.types.1') }}</van-radio>
              <van-radio :name="2" :disabled="!!$route.query.parcel">{{ $t('workOrder.types.2') }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
           readonly
           clickable
           v-if="workOrderForm.type === 1"
           v-model="workOrderForm.question"
           :label="$t('workOrder.question')"
           :placeholder="$t('workOrder.placeholder.question')"
           :rules="[{ required: true }]"
           @click="showQuestionPicker = true"
        />
        <van-field
          readonly
          v-if="workOrderForm.type === 1"
          :clickable="!this.$route.query.parcel && this.orders.length > 0"
          :value="workOrderForm.billNumber"
          :label="$t('workOrder.billNumber')"
          :placeholder="$t('workOrder.placeholder.billNumber')"
          :rules="[{ required: true }]"
          @click="openOrdersPicker"
        />
        <van-field
          v-model="workOrderForm.title"
          clearable
          :label="$t('workOrder.title')"
          :placeholder="$t('workOrder.placeholder.title')"
          :rules="[
            { required: true },
          ]"
        />
        <van-field
          v-model="workOrderForm.description"
          clearable
          type="textarea"
          :rows="3"
          autosize
          :label="$t('workOrder.description')"
          :placeholder="$t('workOrder.placeholder.description')"
          :rules="[
            { required: true },
          ]"
        />
        <van-field
          readonly
          v-model="workOrderForm.email"
          :label="$t('workOrder.email')"
        />
        <van-field
          v-model="workOrderForm.phone"
          :label="$t('workOrder.phone')"
          :placeholder="$t('workOrder.placeholder.phone')"
          :rules="[
            { required: true },
            { validator: validatePhone, message: $t('address.validate_messages.phone_error') },
          ]"
        />
        <van-field :label="$t('workOrder.attachment')">
          <template #input>
            <van-uploader
                v-model="attachments"
                multiple
                :max-count="3"
                :max-size="attachmentsMaxSize"
                accept="image/jpeg, image/png, image/gif, application/vnd.ms-excel, application/pdf, application/zip"
                @oversize="onOversize"
            >
              <van-button class="d-block" size="small" icon="plus">{{ $t('workOrder.upload_attachment') }}</van-button>
            </van-uploader>
          </template>
        </van-field>
        <van-cell>
          <van-button round block type="info" @click.prevent="submit">
            {{ $t('workOrder.submit') }}
          </van-button>
        </van-cell>
      </van-cell-group>
    </van-form>

    <van-popup v-model="showQuestionPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="questionColumns"
        @confirm="onQuestionConfirm"
        @cancel="showQuestionPicker = false"
      />
    </van-popup>

    <van-popup v-model="showOrdersPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="parcelCodes"
        @confirm="onOrderConfirm"
        @cancel="showOrdersPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  NavBar, Uploader,
} from 'vant';
import { phoneValidator, showErrorMessage } from '@/helpers';

Vue.use(NavBar);
Vue.use(Uploader);

export default {
  name: 'CreateWorkOrder',
  mounted() {
    if (!this.currentUser.email) {
      this.$dialog.alert({
        message: this.$t('common.bind_email_tips'),
      }).then(() => {
        this.$router.push({ name: 'userinfo' });
      });
    }
    this.workOrderForm.email = this.currentUser.email;
  },
  data() {
    const questionIds = [
      300, 301, 302,
      303, 304, 308,
    ];
    const questionColumns = [];
    questionIds.forEach((id) => {
      questionColumns.push(this.$t(`workOrder.questionId.${id}`));
    });
    return {
      orders: [],
      parcelCodes: [],
      showQuestionPicker: false,
      showOrdersPicker: false,
      questionIds,
      questionColumns,
      attachments: [],
      attachmentsMaxSize: 1024 ** 2 * 2,
      workOrderForm: {
        type: 1,
        question_id: null,
        question: '',
        email: '',
        phone: '',
        title: '',
        description: '',
        billNumber: '',
        attachments: [],
      },
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      phoneAreaCodes: state => state.app.phoneAreaCodes,
    }),
  },
  watch: {
    '$route.query.parcel': {
      handler(parcel) {
        if (parcel) {
          this.workOrderForm.billNumber = parcel;
        } else {
          this.$toast.loading({
            message: this.$t('common.loading'),
            forbidClick: true,
            duration: 0,
            overlay: true,
          });
          this.$api.order.get({ perpage: 999 }).then(({ data: response }) => {
            this.orders = response.data;
            this.parcelCodes = _.map(this.orders, 'parcel_code');
          }).catch((e) => {
            showErrorMessage(e);
          }).finally(() => {
            this.$toast.clear();
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    validatePhone(phone) {
      const countryCodes = ['HK', 'CN'];
      for (let i = 0; i < countryCodes.length; i++) {
        if (phoneValidator(phone, countryCodes[i])) {
          return true;
        }
      }
      return false;
    },
    // beforeRead(event) {
    //   console.log(111);
    //   const allow = ['application/msword', 'application/vnd.ms-excel',
    //     'application/pdf', 'application/zip', 'image/jpeg', 'image/png', 'image/gif'];
    //   const { file, callback } = event.detail;
    //   console.log(file.type);
    //   if (~allow.indexOf(file.type)) {
    //     callback(file.type);
    //   } else {
    //     this.$toast.fail('文件格式錯誤');
    //   }
    // },
    onOversize() {
      this.$toast.fail(this.$t('common.file_size_max', { size: '2M' }));
    },
    openOrdersPicker() {
      if (!this.$route.query.parcel && this.orders.length) {
        this.showOrdersPicker = true;
      }
    },
    onQuestionConfirm(value, index) {
      this.workOrderForm.question_id = this.questionIds[index];
      this.workOrderForm.question = value;
      this.showQuestionPicker = false;
    },
    onOrderConfirm(value) {
      this.workOrderForm.billNumber = value;
      this.showOrdersPicker = false;
      if (!this.workOrderForm.phone || !this.validatePhone(this.workOrderForm.phone)) {
        const order = _.find(this.orders, { parcel_code: value });
        this.workOrderForm.phone = order.sender_address.phone || '';
      }
    },
    submit() {
      this.$refs.form.validate().then(() => {
        const params = { ...this.workOrderForm };
        if (this.workOrderForm.type === 2) {
          params.question_id = 304;
          params.question = this.$t('workOrder.questionId.304');
          params.billNumber = '';
        }
        if (this.attachments.length) {
          this.attachments.forEach(({ file, content }) => {
            params.attachments.push({
              name: file.name,
              data: content,
            });
          });
        }
        this.$toast.loading({
          message: this.$t('common.saving'),
          forbidClick: true,
          duration: 0,
          overlay: true,
        });
        this.$api.workOrder.post(params).then(() => {
          this.$toast.success(this.$t('workOrder.create_success'));
          this.$router.push({ name: 'work-orders' });
        }).catch((e) => {
          showErrorMessage(e);
        }).finally(() => {
          this.$toast.clear();
        });
      }).catch(() => {
        this.$toast.fail(this.$t('workOrder.validate_error'));
      });
    },
  },
};
</script>

<style lang="scss">

</style>
