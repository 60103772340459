<template>
  <div class="dialogues-box">
    <ul id="message-box">
      <li
        v-for="dialogue in dialogues"
        :key="dialogue.id"
        :class="{ mine: dialogue.mine }"
      >
        <div class="chat-user">
          <van-image class="avatar" :src="currentUser.headimgurl" round v-if="dialogue.mine" />
          <van-image class="avatar" :src="csAvatar" round v-else />
          <div class="info">
            <span class="mr-2" v-if="!dialogue.mine">{{ dialogue.user_name }}</span>
            <i>{{ dialogue.created_at }}</i>
            <span class="ml-2" v-if="dialogue.mine">{{ currentUser.nickname }}</span>
          </div>
        </div>
        <div class="chat-text">
          {{ dialogue.message }}
          <a
            href="javascript:void(0)"
            @click="imagePreview(dialogue.attachments)"
            class="d-block"
            v-if="dialogue.attachments.length"
          >
            {{ $t('workOrder.view_attachments') }}
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ImagePreview } from 'vant';
import jump from 'jump.js';
import csAvatar from '../../assets/cs-avatar.jpeg';

export default {
  name: 'DialoguesBox',
  props: {
    dialogues: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      csAvatar,
    };
  },
  watch: {
    dialogues: {
      handler() {
        jump(999999999999999);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    imagePreview(imgs) {
      ImagePreview(imgs);
    },
  },
};
</script>

<style lang="scss">
  @import "../../style/variables";
  .dialogues-box {
    padding: 0.5rem 1rem 0;
    overflow-x: hidden;
    overflow-y: auto;
    ul {
      li {
        position: relative;
        font-size: 0;
        margin-bottom: 1rem;
        padding-left: 60px;
        min-height: 68px;
        .chat-user {
          position: absolute;
          left: 3px;
          display: inline-block;
          vertical-align: top;
          .avatar {
            width: 40px;
            height: 40px;
          }
          .info {
            position: absolute;
            font-size: 0.8rem;
            line-height: 1.6rem;
            left: 60px;
            top: -2px;
            color: #999;
            width: 500px;
            text-align: left;
            white-space: nowrap;
          }
        }
        .chat-text {
          display: inline-block;
          vertical-align: top;
          position: relative;
          font-size: 0.8rem;
          line-height: 1.2rem;
          margin-top: 25px;
          padding: 8px 15px;
          border-radius: 3px;
          color: #333;
          background-color: #e2e2e2;
          word-break: break-all;
          a {
            text-decoration: underline;
          }
          &:after {
            content: '';
            position: absolute;
            left: -10px;
            top: 13px;
            width: 0;
            height: 0;
            border-style: solid dashed dashed;
            border-color: #e2e2e2 transparent transparent;
            overflow: hidden;
            border-width: 10px;
          }
        }
        &.mine {
          text-align: right;
          padding-left: 0;
          padding-right: 60px;
          .chat-user {
            left: auto;
            right: 3px;
            .info {
              left: auto;
              right: 60px;
              text-align: right;
            }
          }
          .chat-text {
            margin-left: 0;
            text-align: left;
            background-color: $green;
            color: #fff;
            &:after {
              left: auto;
              right: -10px;
              border-top-color: $green;
            }
            a {
              color: white;
            }
          }
        }
      }
    }
  }
</style>
