<template>
  <div class="message-input-box">
    <van-field v-model="message" v-show="!showPopup" autofocus>
      <van-button slot="button" size="small" type="default" class="mr-1" round icon="plus" @click="showPopup = true"></van-button>
      <van-button slot="button" size="small" type="primary" @click.prevent="sendMessage" :disabled="sendDisabled">{{ $t('workOrder.send') }}</van-button>
    </van-field>

    <van-popup
      v-model="showPopup"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <van-field v-model="message" autofocus>
        <van-button slot="button" size="small" type="default" class="mr-1" round icon="arrow-down" @click="showPopup = false"></van-button>
        <van-button slot="button" size="small" type="primary" @click.prevent="sendMessage" :disabled="sendDisabled">{{ $t('workOrder.sending') }}</van-button>
      </van-field>
      <div class="p-3 message-input-box-popup">
        <van-uploader
          v-model="attachments"
          multiple
          :max-count="3"
          :max-size="attachmentsMaxSize"
          accept="image/jpeg, image/png, image/gif, application/vnd.ms-excel, application/pdf, application/zip"
          @oversize="onOversize"
        >
          <van-button class="d-block" size="small" icon="plus">{{ $t('workOrder.upload_attachment') }}</van-button>
        </van-uploader>
      </div>

    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { Uploader } from 'vant';
import { showErrorMessage } from '@/helpers';

Vue.use(Uploader);

export default {
  name: 'MessageInputBox',
  props: {
    woId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      message: '',
      uploadShow: false,
      attachments: [],
      attachmentsMaxSize: 1024 ** 2 * 2,
      showPopup: false,
    };
  },
  computed: {
    sendDisabled() {
      return !this.message.trim();
    },
  },
  methods: {
    onOversize() {
      this.$toast.fail(this.$t('common.file_size_max', { size: '2M' }));
    },
    sendMessage() {
      if (!this.message.trim()) {
        this.$toast.fail(this.$t('workOrder.please_input_message'));
        return;
      }
      this.$toast.loading({
        message: this.$t('workOrder.send'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      const params = { message: this.message, attachments: [] };
      if (this.attachments.length) {
        this.attachments.forEach(({ file, content }) => {
          params.attachments.push({
            name: file.name,
            data: content,
          });
        });
      }
      this.$api.workOrder.sendMessage(this.$route.params.id, params).then(({ data: response }) => {
        this.showPopup = false;
        this.$emit('update', response.data);
        this.message = '';
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
    },
  },
};
</script>

<style lang="scss">
  .message-input-box {
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: content-box;
    max-height: 141px;
    /*min-height: 84px;*/
    width: 100%;
    z-index: 100;
    .van-field__control {
      padding: 3px;
      border: 1px solid #ebedf0;
      border-radius: 2px;
    }
  }
  .message-input-box-popup {
    .van-uploader__input-wrapper {
      .van-button {
        width: 80px;
        height: 80px;
      }
    }
  }
</style>
