<template>
  <div id="work-order-detail">
    <div class="nav-bar-fixed">
      <van-nav-bar
        :title="wo ? wo.title : ''"
        left-arrow
        @click-left="() => $router.back()"
      >
        <template #right>
          <van-button v-if="wo" size="small" type="primary" @click="showInfoPopup = true">
            {{ $t('workOrder.info') }}
          </van-button>
        </template>
      </van-nav-bar>
    </div>
    <div class="dialogues-container" v-if="wo">
      <dialogues-box :dialogues="wo.dialogues" />
    </div>
    <message-input-box :wo-id="wo.id" v-if="wo" @update="data => wo = data" />
    <van-popup
      class="work-order-detail-popup"
      v-model="showInfoPopup"
      position="right"
      get-container="#app"
      :style="{ width: '100%', height: '100%' }"
    >
      <van-nav-bar
        :title="$t('workOrder.info')"
        left-arrow
        @click-left="showInfoPopup = false"
      />
      <van-cell-group v-if="wo">
        <van-cell
          :title="$t('workOrder.refference_no')"
          :value="wo.refference_no"
        />
        <van-cell
          :title="$t('workOrder.status')"
          :value="$t(`workOrder.statuses.${wo.status}`)"
        />
        <van-cell
          :title="$t('workOrder.question')"
          :value="$t(`workOrder.questionId.${wo.question_id}`)"
        />
        <van-cell
          :title="$t('workOrder.billNumber')"
          :value="wo.bill_number"
        />
        <van-cell
          :title="$t('workOrder.title')"
          :value="wo.title"
        />
        <van-cell
          :title="$t('workOrder.description')"
          :value="wo.description"
        />
        <van-cell :title="$t('workOrder.attachment')">
          <template #label>
            <van-image
              v-for="(url, index) in wo.data.attachments"
              :key="index"
              :src="url"
              @click="imagePreview(url)"
              width="100"
              height="100"
              fit="contain"
              class="mr-1 mb-1"
            >
              <template v-slot:error>
                <a :href="url" target="_blank" class="text-center">
                  <van-icon class="d-block" size="3rem" name="description" />
                  {{ url.substring(url.lastIndexOf('.') + 1) }}
                </a>
              </template>
            </van-image>
          </template>
        </van-cell>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { showErrorMessage, urlIsImage } from '@/helpers';
import {
  PullRefresh, NavBar, Badge, ImagePreview,
} from 'vant';
import DialoguesBox from '@/components/workOrder/DialoguesBox';
import MessageInputBox from '@/components/workOrder/MessageInputBox';

Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(Badge);
Vue.use(ImagePreview);

export default {
  name: 'WordOrderDetail',
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      wo: null,
      showInfoPopup: false,
      interval: null,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.getWorkOrder();
        this.interval = setInterval(this.getWorkOrder, 5000);
      },
      immediate: true,
    },
  },
  methods: {
    getWorkOrder() {
      if (!this.wo) {
        this.$toast.loading({
          message: this.$t('common.loading'),
          forbidClick: true,
          duration: 0,
          overlay: true,
        });
      }
      this.$api.workOrder.getById(this.$route.params.id).then(({ data: response }) => {
        this.wo = response.data;
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
    },
    imagePreview(url) {
      if (urlIsImage(url)) {
        ImagePreview([url]);
      }
    },
  },
  components: {
    DialoguesBox,
    MessageInputBox,
  },
};
</script>

<style lang="scss">
  @import "../../style/variables";
  #work-order-detail {
    .nav-bar-fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 46px;
      z-index: 2000;
    }
    .dialogues-container {
      /*position: fixed;*/
      margin-top: 46px;
      /*top: 46px;*/
      height: 100%;
      /*overflow-y: scroll;*/
      /*padding-bottom: 100px;*/
      min-width: 100%;
      #message-box {
        padding-bottom: 30px;
      }
    }
  }
  .work-order-detail-popup {
    .van-image__error {
      a {
        overflow: hidden;
        text-decoration: none;
        color: $gray-6;
      }
    }
  }
</style>
