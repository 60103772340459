<template>
  <div id="work-orders">
    <van-nav-bar
      :title="$t('workOrder.list')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.not_more')"
        @load="onLoad"
      >
        <van-cell-group
          class="order-cell"
          v-for="wo in list"
          :key="wo.id"
        >
          <van-cell
            :to="{ name: 'work-order-detail', params: { id: wo.id }}"
            :title="wo.title"
            :value="$t(`workOrder.statuses.${wo.status}`)"
            is-link
          >
            <template #label>
              <span class="d-block" v-if="wo.bill_number">{{ wo.bill_number }}</span>
              <span class="d-block">{{ wo.created_at }}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <div class="create-button">
      <van-button round block type="danger" @click="$router.push({ name: 'create-work-order' })">
        <van-icon name="edit" />
        {{ $t('workOrder.create') }}
      </van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  NavBar,
  PullRefresh,
  List,
} from 'vant';

Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(List);

export default {
  name: 'WorkOrderList',
  mounted() {
    this.onRefresh();
  },
  data() {
    return {
      list: [],
      search_parcel_code: '',
      loading: false,
      refreshing: false,
      finished: false,
      pagination: null,
      page: 1,
    };
  },
  computed: {},
  methods: {
    onRefresh() {
      this.list = [];
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onCancelSearch() {
      this.list = [];
      this.page = 1;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      const params = { status: this.$route.params.status };
      if (this.search_parcel_code) {
        this.list = [];
        this.page = 1;
        params.code = this.search_parcel_code;
      } else {
        params.page = this.page;
      }
      this.$api.workOrder.get(params).then(({ data: response }) => {
        this.pagination = response.meta.pagination;
        if (this.page < this.pagination.total_pages) {
          this.page++;
        }
        this.list = [...this.list, ...response.data];
        if (this.list.length === this.pagination.total) {
          this.finished = true;
        }
      }).catch(() => {
        this.finished = true;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
  #work-orders {
    .create-button {
      width: 100%;
      padding: 10px;
      position: absolute;
      bottom: 84px;
    }
  }
</style>
